import React from "react";
import "./login.css";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
} from "mdbreact";

import { Link } from "react-router-dom";

function login() {
  return (
    <div className="wrapper">
      <MDBContainer className="mt-5">
        <MDBRow>
          <MDBCol md="5" className="justify-content-center m-auto">
            <MDBCard className="cloudy-knoxville-gradient">
              <MDBCardBody>
                <MDBCardHeader className="form-header blue-gradient rounded mt-2">
                  <h3 className="my-3 text-center text-white">
                    <MDBIcon icon="lock" /> Login
                  </h3>
                </MDBCardHeader>
                <form>
                  <div className="grey-text mt-5">
                    <MDBInput
                      label="Type your email"
                      icon="envelope"
                      group
                      type="email"
                      validate
                      error="wrong"
                      success="right"
                    />
                    <MDBInput
                      label="Type your password"
                      icon="lock"
                      group
                      type="password"
                      validate
                    />
                  </div>

                  <div className="text-center mt-4">
                    <Link to="/dashboard">
                      <MDBBtn color="light-blue" className="mb-3" type="submit">
                        Login
                      </MDBBtn>
                    </Link>
                  </div>
                </form>
                <MDBModalFooter>
                  <div className="font-weight-light">
                    {/* <p>Not a account? Sign Up</p>
                    <p>Forgot Password?</p> */}
                  </div>
                </MDBModalFooter>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}

export default login;
