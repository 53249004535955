import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./Components/Login/login";
import Dashboard from "./Components/Dashboard/Dashboard";
import PostCategory from "./Components/CategoryPage/CategoryPage";
import AddPost from "./Components/Post/AddNewPost";
import AddMobile from "./Components/Post/AddMobile";
import AddLaptop from "./Components/Post/AddLaptop";
import Api from "./Components/Api/Laptop";

export default function App() {
  return (
    <div>
      <Router>
        <Route exact path="/" component={Home} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/addPost" component={AddPost} />
        <Route path="/choose_category" component={PostCategory} />
        <Route path="/add_mobile" component={AddMobile} />
        <Route path="/add_laptop" component={AddLaptop} />
        <Route exact path="/api" component={Api} />
      </Router>
    </div>
  );
}
