import React, { Fragment } from "react";
import { MDBBtn, MDBContainer, MDBAnimation } from "mdbreact";
import Header from "../Header/header";
import "./CategoryPage.css";
import { Link } from 'react-router-dom';

function Category() {
  alert("Not Available!");
}
export default function CategoryPage() {
  return (
    <div className="cloudy-knoxville-gradient bg">
      <Header />
      <MDBContainer className="contentc">
        <MDBAnimation
          type="fadeInLeft"
          delay=".3s"
          className=" text-center text-md-left mt-xl-5 mb-2"
        >
          <h2 className="h2-responsive font-weight-bold text-center">
            Choose your Desired Category
          </h2>
          <hr className="hr-light" />
        </MDBAnimation>

        <MDBAnimation
          type="fadeInRight"
          delay=".3s"
          className=" text-center text-md-left mt-xl-5 mb-2"
        >
          <div className="m-auto text-center pt-2">
            <Fragment>
              <Link to="/add_mobile">
                <MDBBtn
                  className="btn-outline-primary"
                  size="lg"
                  color="primary"
                  rounded
                  outline
                >
                  Mobile
                </MDBBtn>
              </Link>
              <Link to="/add_laptop">
                <MDBBtn
                  className="btn-outline-primary"
                  size="lg"
                  rounded
                  outline
                >
                  Laptop
                </MDBBtn>
              </Link>
              <MDBBtn
                className="btn-outline-primary"
                size="lg"
                rounded
                outline
                color="secondary"
                onClick={Category}
              >
                Gadgets
              </MDBBtn>
              <MDBBtn
                className="btn-outline-primary"
                size="lg"
                rounded
                outline
                color="success"
                onClick={Category}
              >
                Books
              </MDBBtn>
              <MDBBtn
                className="btn-outline-primary"
                size="lg"
                rounded
                outline
                color="info"
                onClick={Category}
              >
                Linux fix
              </MDBBtn>
              <MDBBtn
                className="btn-outline-primary"
                size="lg"
                rounded
                outline
                color="warning"
                onClick={Category}
              >
                Server fixed
              </MDBBtn>
            </Fragment>
          </div>
        </MDBAnimation>
      </MDBContainer>
    </div>
  );
}
