import React from "react";
import { Doughnut } from "react-chartjs-2";
import { MDBCol, MDBContainer, MDBRow, MDBIcon } from "mdbreact";
import Header from "../Header/header";
import './Dashboard.css'
import { Link } from 'react-router-dom';

class ChartsPage extends React.Component {
state = {
  dataDoughnut: {
    labels: ["Mobile", "Laptop", "Gadgets", "Books", "Linux fix","Server fixed"],
    datasets: [
      {
        data: [300, 50, 100, 40, 120,50],
        backgroundColor: ["#F7464A", "#46BFBD", "#FDB45C", "#949FB1", "#4D5360", "#46BFBD"],
        hoverBackgroundColor: [
          "#FF5A5E",
          "#5AD3D1",
          "#FFC870",
          "#A8B3C5",
          "#616774",
          "#5AD3D1"
        ]
      }
    ]
  }
}

render() {
    return (
        <div>
        <Header/>
    <MDBContainer>
      <MDBRow>
        <MDBCol lg="9" md="12">
        <h3 className="mt-5 text-center mb-5">Post Counter</h3>
      <Doughnut data={this.state.dataDoughnut} options={{ responsive: true }} />
        </MDBCol>
        <MDBCol lg="3" md="12" >
          <div className='centered'>
          <Link to="/choose_category">
          <MDBIcon far icon="caret-square-right" size='7x' className='text-primary' />
          <p className='h5'> Create post</p>
          </Link>
          </div>
        </MDBCol>
      </MDBRow>
      
    </MDBContainer>
    </div>
    );
  }
}

export default ChartsPage;