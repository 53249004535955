import { MDBContainer } from "mdbreact";
import React, { useState } from "react";
// import firebase from "./firebase-config";
import Header from "../Header/header";

const AddNewPost = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const handleSubmit = (event) => {
    // event.preventDefault();
    // firebase
    //   .app("otherApp")
    //   .firestore()
    //   .collection("blog")
    //   .add({
    //     title: title,
    //     content: content
    //   });
    // setTitle("");
    // setContent("");
    // setTimeout(() => {
    //   alert("Blogpost created");
    // }, 2000);
  };

  //   console.log({
  //     "title": title,
  //     "content": content
  //   });

  return (
    <div>
      <Header />
      <MDBContainer>
        <h2>Add Blog</h2>
        <form onSubmit={handleSubmit}>
          <div>
            Title
            <br />
            <input
              type="text"
              value={title}
              onChange={({ target }) => setTitle(target.value)}
            />
          </div>
          <div>
            Content
            <br />
            <textarea
              value={content}
              onChange={({ target }) => setContent(target.value)}
            />
          </div>
          <button type="submit">Save</button>
        </form>
      </MDBContainer>
    </div>
  );
};
export default AddNewPost;
