import firebase from "firebase/app";
import "firebase";

const firebaseConfigother = {
  apiKey: "AIzaSyDVCNpbukOXHLwO44haGaFFFRs7BtFCdeI",
  authDomain: "nextproject-28960.firebaseapp.com",
  databaseURL: "https://nextproject-28960.firebaseio.com",
  projectId: "nextproject-28960",
  storageBucket: "nextproject-28960.appspot.com",
  messagingSenderId: "610899302651",
  appId: "1:610899302651:web:5b7f29fbb88288d968385e",
  measurementId: "G-5SWXFFEH0E",
};
// try {
//   firebase.initializeApp(firebaseConfigother, "otherApp");
// } catch (err) {
//   if (!/already exists/.test(err.message)) {
//     console.error("Firebase initialization error", err.stack);
//   }
// }
// const fire = firebase;
// // console.log(fire.name);
firebase.initializeApp(firebaseConfigother, "otherApp");
export default firebase;
