import React, { useState } from "react";
import {
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBCard,
  MDBIcon,
  MDBBtn,
  MDBCardBody,
  MDBProgress,
} from "mdbreact";
import SunEditor from "suneditor-react";
import Header from "../Header/header";
import "./AddMobile.css";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import firebase from "./firebase-config";

export default function AddMobile() {
  const [title, setTitle] = useState("");
  const [sDiscription, setSDiscription] = useState("");
  const [upload, setUpload] = useState("");
  const [brand, setBrand] = useState("");
  const [modal, setModal] = useState("");
  const [launch, setLaunch] = useState("");
  const [factor, setFactor] = useState("");
  const [charging, setCharging] = useState("");
  const [color, setColor] = useState("");
  const [display, setDisplay] = useState("");
  const [touch, setTouch] = useState("");
  const [resolution, setResolution] = useState("");
  const [processor, setProcessor] = useState("");
  const [processormake, setProcessormake] = useState("");
  const [frontCam, setFrontCam] = useState("");
  const [noofFrontcam, setNoOfFrontCam] = useState("");
  const [rearCam, setRearCam] = useState("");
  const [rearCamFocus, setRearCamFocus] = useState("");
  const [rearCamFlash, setRearCamFlash] = useState("");
  const [noofrearCam, setNorearCam] = useState("");
  const [popupcamera, setPopUpCamera] = useState("");
  const [ram, setRam] = useState("");
  const [os, setOs] = useState("");
  const [storage, setStorage] = useState("");
  const [expstorage, setExpstorage] = useState("");
  const [expstoragetype, setExpstoragetype] = useState("");
  const [expstorageupto, setExpstorageupto] = useState("");
  const [battery, setBattery] = useState("");
  const [dimension, setDimension] = useState("");
  const [weight, setWeight] = useState("");
  const [detailDes, setDetailDes] = useState("");
  const [progress, setProgress] = useState(NaN);
  const [purl, setPUrl] = useState("");
  const [wifi, setWifi] = useState("");
  const [gps, setGps] = useState("");
  const [blue, setBlue] = useState("");
  const [nfc, setNfc] = useState("");
  const [usb, setUsb] = useState("");
  const [headphone, setHeadPhone] = useState("");
  const [fm, setFm] = useState("");
  const [simNum, setSinNum] = useState(Number);
  const [activeSim, setActiveSim] = useState("");
  const [facelock, setFaceLock] = useState("");
  const [proximitySensor, setProximitySensor] = useState("");
  const [accelerometer, setAccelator] = useState("");
  const [ambientSensor, setambientSensor] = useState("");
  const [gyroscope, setGyroscope] = useState("");
  const [amazone, setAmazone] = useState("");
  const [flipkart, setFlipkart] = useState("");
  const [unboxing, setUnboxing] = useState("");
  const [review, setReview] = useState("");

  const filename = upload?.name;
  const fileExtension = filename?.split(".").pop();
  ///url function
  function findAndReplace(string, target, replacement) {
    var i = 0,
      length = string.length;
    for (i; i < length; i++) {
      string = string.replace(target, replacement);
    }
    return string;
  }
  const url = findAndReplace(title, " ", "_");
  // console.log(url);

  const metadata = {
    contentType: `image/${fileExtension}`,
  };

  const uploadImage = () => {
    const uploadTask = firebase
      .app("otherApp")
      .storage()
      .ref("mobile")
      .child(upload.name)
      .put(upload, metadata);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        alert(error.message);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          setPUrl(url);
        });
      }
    );
  };

  const submit = () => {
    if (
      (url &&
        title &&
        sDiscription &&
        purl &&
        brand &&
        modal &&
        launch &&
        factor &&
        color &&
        charging &&
        display &&
        touch &&
        resolution &&
        processor &&
        processormake &&
        frontCam &&
        rearCam &&
        noofrearCam &&
        rearCamFocus &&
        rearCamFlash &&
        noofFrontcam &&
        popupcamera &&
        ram &&
        os &&
        storage &&
        expstorage &&
        expstoragetype &&
        expstorageupto &&
        battery &&
        dimension &&
        weight &&
        detailDes &&
        wifi &&
        gps &&
        blue &&
        nfc &&
        usb &&
        headphone &&
        fm &&
        simNum &&
        activeSim &&
        facelock &&
        proximitySensor &&
        accelerometer &&
        ambientSensor &&
        gyroscope &&
        amazone,
      flipkart,
      unboxing,
      review)
    ) {
      // alert("submit");
      firebase
        .app("otherApp")
        .firestore()
        .collection("mobile")
        .doc(url)
        .set({
          startAt: firebase.firestore.FieldValue.serverTimestamp(),
          wifi,
          gps,
          blue,
          nfc,
          usb,
          headphone,
          fm,
          simNum,
          activeSim,
          facelock,
          proximitySensor,
          accelerometer,
          ambientSensor,
          gyroscope,
          amazone,
          flipkart,
          unboxing,
          review,
          title,
          sDiscription,
          purl,
          brand,
          modal,
          launch,
          factor,
          color,
          charging,
          display,
          touch,
          processor,
          processormake,
          frontCam,
          popupcamera,
          noofFrontcam,
          rearCam,
          noofrearCam,
          rearCamFocus,
          rearCamFlash,
          ram,
          os,
          storage,
          expstorage,
          expstoragetype,
          expstorageupto,
          battery,
          dimension,
          weight,
          resolution,
          detailDes,
        })
        .then(() => {
          alert("Post Created Successfully");
        });
    } else {
      alert("filled are empty");
    }
  };

  return (
    <div>
      <Header />
      <MDBContainer>
        <p className="h3 mt-5"> Add Mobile</p>
        <MDBCard className="mt-2">
          <MDBCardBody>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">Upload</span>
              </div>
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  aria-describedby="inputGroupFileAddon01"
                  // value={upload}
                  onChange={(e) => setUpload(e.target.files[0])}
                />
                <label className="custom-file-label" htmlFor="inputGroupFile01">
                  {upload ? upload?.name : "choose file"}
                </label>
              </div>
            </div>
            <MDBProgress value={progress} className="my-3 " color="info" />
            <div className="text-center mt-1">
              <MDBBtn
                onClick={() => uploadImage()}
                outline
                color="info btn-outline-primary"
              >
                Upload &nbsp;
                <MDBIcon icon="upload" className="ml-1 " />
              </MDBBtn>
            </div>
            <hr></hr>
            {/* one form */}
            <div className="form-group mt-5">
              <label htmlFor="formGroupExampleInput">Your Post Title</label>
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />

              <label htmlFor="formGroupExampleInput">
                Short discription about your post
              </label>
              <input
                type="text"
                className="form-control"
                value={sDiscription}
                onChange={(e) => setSDiscription(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="formGroupExampleInput">Photo url</label>
              <input
                type="text"
                className="form-control"
                value={purl}
                onChange={() => setPUrl(purl)}
              />
            </div>
            <hr className="new"></hr>
            <p className="h4 mt-3">General</p>
            <hr className="new"></hr>
            <MDBRow className="mt-2">
              <MDBCol>
                <label htmlFor="formGroupExampleInput">Brand</label>
                <input
                  type="text"
                  className="form-control"
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                />
              </MDBCol>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">Modal</label>
                <input
                  type="text"
                  className="form-control"
                  value={modal}
                  onChange={(e) => setModal(e.target.value)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-2">
              <MDBCol>
                <label htmlFor="formGroupExampleInput">Launched in India</label>
                <input
                  type="text"
                  className="form-control"
                  value={launch}
                  onChange={(e) => setLaunch(e.target.value)}
                />
              </MDBCol>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">Form Factor</label>
                <input
                  type="text"
                  className="form-control"
                  value={factor}
                  onChange={(e) => setFactor(e.target.value)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-2">
              <MDBCol>
                <label htmlFor="formGroupExampleInput"> Dimensions</label>
                <input
                  type="text"
                  className="form-control"
                  value={dimension}
                  onChange={(e) => setDimension(e.target.value)}
                />
              </MDBCol>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">Weight</label>
                <input
                  type="text"
                  className="form-control"
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-2">
              <MDBCol>
                <label htmlFor="formGroupExampleInput">Battery Capacity</label>
                <input
                  type="text"
                  className="form-control"
                  value={battery}
                  onChange={(e) => setBattery(e.target.value)}
                />
              </MDBCol>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">Fast charging</label>
                <input
                  type="text"
                  className="form-control"
                  value={charging}
                  onChange={(e) => setCharging(e.target.value)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">Colors</label>
                <input
                  type="text"
                  className="form-control"
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                />
              </MDBCol>
              <MDBCol></MDBCol>
            </MDBRow>
            <hr className="new"></hr>
            <p className="h4 mt-3">Display</p>
            <hr className="new"></hr>
            <MDBRow className="mt-2">
              <MDBCol>
                <label htmlFor="formGroupExampleInput">
                  Screen size (inches)
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={display}
                  onChange={(e) => setDisplay(e.target.value)}
                />
              </MDBCol>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">
                  Touchscreen(Yes/No)
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={touch}
                  onChange={(e) => setTouch(e.target.value)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">Resolution</label>
                <input
                  type="text"
                  className="form-control"
                  value={resolution}
                  onChange={(e) => setResolution(e.target.value)}
                />
              </MDBCol>
              <MDBCol></MDBCol>
            </MDBRow>
            <hr className="new"></hr>
            <p className="h4 mt-3">Hardware</p>
            <hr className="new"></hr>
            <MDBRow>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">Processor</label>
                <input
                  type="text"
                  className="form-control"
                  value={processor}
                  onChange={(e) => setProcessor(e.target.value)}
                />
              </MDBCol>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">Processor Make</label>
                <input
                  type="text"
                  className="form-control"
                  value={processormake}
                  onChange={(e) => setProcessormake(e.target.value)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">RAM</label>
                <input
                  type="text"
                  className="form-control"
                  value={ram}
                  onChange={(e) => setRam(e.target.value)}
                />
              </MDBCol>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">Internal Storage</label>
                <input
                  type="text"
                  className="form-control"
                  value={storage}
                  onChange={(e) => setStorage(e.target.value)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">
                  Expandable storage
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={expstorage}
                  onChange={(e) => setExpstorage(e.target.value)}
                />
              </MDBCol>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">
                  Expandable storage type(microSD or usb)
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={expstoragetype}
                  onChange={(e) => setExpstoragetype(e.target.value)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">
                  Expandable storage up to (GB)
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={expstorageupto}
                  onChange={(e) => setExpstorageupto(e.target.value)}
                />
              </MDBCol>

              <MDBCol></MDBCol>
            </MDBRow>
            <hr className="new"></hr>
            <p className="h4 mt-3">Camera</p>
            <hr className="new"></hr>
            <MDBRow>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">Rear Camera</label>
                <input
                  type="text"
                  className="form-control"
                  value={rearCam}
                  onChange={(e) => setRearCam(e.target.value)}
                />
              </MDBCol>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">
                  No. of Rear Cameras{" "}
                </label>
                <input
                  type="number"
                  className="form-control"
                  value={noofrearCam}
                  onChange={(e) => setNorearCam(e.target.value)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">
                  Rear autofocus(Yes/No)
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={rearCamFocus}
                  onChange={(e) => setRearCamFocus(e.target.value)}
                />
              </MDBCol>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">
                  Rear flash(Yes/No)
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={rearCamFlash}
                  onChange={(e) => setRearCamFlash(e.target.value)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">Front Camera</label>
                <input
                  type="text"
                  className="form-control"
                  value={frontCam}
                  onChange={(e) => setFrontCam(e.target.value)}
                />
              </MDBCol>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">
                  No of Front Camera
                </label>
                <input
                  type="number"
                  className="form-control"
                  value={noofFrontcam}
                  onChange={(e) => setNoOfFrontCam(e.target.value)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">
                  Pop-Up Camera(Yes/No)
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={popupcamera}
                  onChange={(e) => setPopUpCamera(e.target.value)}
                />
              </MDBCol>
              <MDBCol></MDBCol>
            </MDBRow>
            <hr className="new"></hr>
            <p className="h4 mt-3">Software</p>
            <hr className="new"></hr>
            <MDBRow>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">Operating system</label>
                <input
                  type="text"
                  className="form-control"
                  value={os}
                  onChange={(e) => setOs(e.target.value)}
                />
              </MDBCol>
              <MDBCol></MDBCol>
            </MDBRow>
            <hr className="new"></hr>
            <p className="h4 mt-3">Connectivity</p>
            <hr className="new"></hr>
            <MDBRow>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">Wi-Fi (Yes/No)</label>
                <input
                  type="text"
                  className="form-control"
                  value={wifi}
                  onChange={(e) => setWifi(e.target.value)}
                />
              </MDBCol>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">GPS</label>
                <input
                  type="text"
                  className="form-control"
                  value={gps}
                  onChange={(e) => setGps(e.target.value)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">
                  Bluetooth (Yes/No) & Version
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={blue}
                  onChange={(e) => setBlue(e.target.value)}
                />
              </MDBCol>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">NFC (Yes/No)</label>
                <input
                  type="text"
                  className="form-control"
                  value={nfc}
                  onChange={(e) => setNfc(e.target.value)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">
                  USB Type-C (Yes/No)
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={usb}
                  onChange={(e) => setUsb(e.target.value)}
                />
              </MDBCol>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">
                  Headphones (Yes/No)
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={headphone}
                  onChange={(e) => setHeadPhone(e.target.value)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">FM (Yes/No)</label>
                <input
                  type="text"
                  className="form-control"
                  value={fm}
                  onChange={(e) => setFm(e.target.value)}
                />
              </MDBCol>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">Number of SIMs</label>
                <input
                  type="text"
                  className="form-control"
                  value={simNum}
                  onChange={(e) => setSinNum(e.target.value)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">
                  Active 4G on both SIM cards(Yes/No)
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={activeSim}
                  onChange={(e) => setActiveSim(e.target.value)}
                />
              </MDBCol>
              <MDBCol></MDBCol>
            </MDBRow>
            <hr className="new"></hr>
            <p className="h4 mt-3">Sensors</p>
            <hr className="new"></hr>
            <MDBRow>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">
                  Face unlock (Yes/No)
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={facelock}
                  onChange={(e) => setFaceLock(e.target.value)}
                />
              </MDBCol>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">
                  Proximity sensor (yes/No)
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={proximitySensor}
                  onChange={(e) => setProximitySensor(e.target.value)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">
                  Accelerometer (Yes/No)
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={accelerometer}
                  onChange={(e) => setAccelator(e.target.value)}
                />
              </MDBCol>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">
                  Ambient light sensor (Yes/No)
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={ambientSensor}
                  onChange={(e) => setambientSensor(e.target.value)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">
                  Gyroscope (Yes/No)
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={gyroscope}
                  onChange={(e) => setGyroscope(e.target.value)}
                />
              </MDBCol>
              <MDBCol></MDBCol>
            </MDBRow>
            <hr className="new"></hr>
            <p className="h4 mt-3">Affiliate link addition</p>
            <hr className="new"></hr>
            <MDBRow>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">Amazon.in</label>
                <input
                  type="text"
                  className="form-control"
                  value={amazone}
                  onChange={(e) => setAmazone(e.target.value)}
                />
              </MDBCol>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">Flipkart</label>
                <input
                  type="text"
                  className="form-control"
                  value={flipkart}
                  onChange={(e) => setFlipkart(e.target.value)}
                />
              </MDBCol>
            </MDBRow>
            <hr className="new"></hr>
            <p className="h4 mt-3">Video link addition</p>
            <hr className="new"></hr>
            <MDBRow>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">Unboxing Video</label>
                <input
                  type="text"
                  className="form-control"
                  value={unboxing}
                  onChange={(e) => setUnboxing(e.target.value)}
                />
              </MDBCol>
              <MDBCol>
                <label htmlFor="formGroupExampleInput">Review Video</label>
                <input
                  type="text"
                  className="form-control"
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                />
              </MDBCol>
            </MDBRow>
            <div className="mt-3">
              <p>Details discription</p>
              <SunEditor height="50vh" onChange={setDetailDes} />
            </div>
            <div className="text-center pt-3">
              <MDBBtn
                onClick={() => submit()}
                outline
                color="info btn-outline-primary"
              >
                Post &nbsp;
                <MDBIcon icon="location-arrow" className="ml-1 " />
              </MDBBtn>
            </div>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </div>
  );
}
