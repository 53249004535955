import React, { useState } from "react";
import {
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBCard,
  MDBIcon,
  MDBBtn,
  MDBCardBody,
  MDBCardHeader,
  MDBProgress,
} from "mdbreact";
import SunEditor from "suneditor-react";
import Header from "../Header/header";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import firebase from "./firebase-config";

export default function AddLaptop() {
  const [title, setTitle] = useState("");
  const [brand, setBrand] = useState("");
  const [ltype, setLtype] = useState("");
  const [sDiscription, setSDiscription] = useState("");
  const [upload, setUpload] = useState("");
  const [screensize, setScreensize] = useState("");
  const [screenspeaker, setScreenspeaker] = useState("");
  const [touchscreen, setTouchscreen] = useState("");
  const [otherdis, setOtherdis] = useState("");
  const [cputype, setCputype] = useState("");
  const [gputype, setGputype] = useState("");
  const [ram, setRam] = useState("");
  const [storage, setStorage] = useState("");
  const [dvd, setDvd] = useState("");
  const [keyboard, setKeyboard] = useState("");
  const [speaker, setSpeaker] = useState("");
  const [camera, setCamera] = useState("");
  const [wifi, setWifi] = useState("");
  const [etherNet, setEtherNet] = useState("");
  const [mobBroad, setMobBroad] = useState("");
  const [bluetooth, setBluetooth] = useState("");
  const [usbPort, setUsbPort] = useState("");
  const [videoPort, setVideoPort] = useState("");
  const [mediaCard, setMediaCard] = useState("");
  const [battery, setBattery] = useState("");
  const [weight, setWeight] = useState("");
  const [dimension, setDimension] = useState("");
  const [windowVersion, setWindowVersion] = useState("");
  const [warranty, setWarranty] = useState("");
  const [detailDes, setDetailDes] = useState("");
  const [progress, setProgress] = useState(NaN);
  const [purl, setPUrl] = useState("");
  const [amazone, setAmazone] = useState("");
  const [flipkart, setFlipkart] = useState("");
  const [unboxing, setUnboxing] = useState("");
  const [review, setReview] = useState("");

  const filename = upload?.name;
  const fileExtension = filename?.split(".").pop();
  // console.log(detailDes);

  ///url function
  function findAndReplace(string, target, replacement) {
    var i = 0,
      length = string.length;
    for (i; i < length; i++) {
      string = string.replace(target, replacement);
    }
    return string;
  }
  const url = findAndReplace(title, " ", "_");
  // console.log(url);

  const metadata = {
    contentType: `image/${fileExtension}`,
  };
  //image uplaod  section
  const uploadImage = () => {
    const uploadTask = firebase
      .app("otherApp")
      .storage()
      .ref("laptop")
      .child(upload.name)
      .put(upload, metadata);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        alert(error.message);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          setPUrl(url);
        });
      }
    );
  };

  const submit = () => {
    if (
      brand &&
      ltype &&
      url &&
      title &&
      sDiscription &&
      purl &&
      screensize &&
      screenspeaker &&
      touchscreen &&
      otherdis &&
      cputype &&
      gputype &&
      ram &&
      storage &&
      dvd &&
      keyboard &&
      speaker &&
      camera &&
      detailDes &&
      amazone &&
      flipkart &&
      unboxing &&
      review &&
      wifi &&
      etherNet &&
      mobBroad &&
      bluetooth &&
      usbPort &&
      videoPort &&
      mediaCard &&
      battery &&
      weight &&
      dimension &&
      windowVersion &&
      warranty
    ) {
      // alert("submit");
      firebase
        .app("otherApp")
        .firestore()
        .collection("laptop")
        .doc(url)
        .set({
          brand,
          wifi,
          etherNet,
          mobBroad,
          bluetooth,
          usbPort,
          videoPort,
          mediaCard,
          battery,
          weight,
          dimension,
          windowVersion,
          warranty,
          ltype,
          title,
          sDiscription,
          purl,
          screensize,
          screenspeaker,
          touchscreen,
          otherdis,
          cputype,
          gputype,
          ram,
          storage,
          dvd,
          keyboard,
          speaker,
          camera,
          detailDes,
          amazone,
          flipkart,
          unboxing,
          review,
        })
        .then(() => {
          alert("Post successfully Created");
        });
    } else {
      alert("filled are empty");
    }
  };

  return (
    <div>
      <Header />
      <MDBContainer>
        <MDBRow className="mt-5">
          <MDBCol lg="9" md="12">
            <p className="h3"> Add Mobile</p>
            <MDBCard className="mt-2">
              <MDBCardBody>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Upload</span>
                  </div>
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      aria-describedby="inputGroupFileAddon01"
                      // value={upload}
                      onChange={(e) => setUpload(e.target.files[0])}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="inputGroupFile01"
                    >
                      {upload ? upload?.name : "choose file"}
                    </label>
                  </div>
                </div>
                <MDBProgress value={progress} className="my-3 " color="info" />
                <div className="text-center mt-1">
                  <MDBBtn
                    onClick={() => uploadImage()}
                    outline
                    color="info btn-outline-primary"
                  >
                    Upload &nbsp;
                    <MDBIcon icon="upload" className="ml-1 " />
                  </MDBBtn>
                </div>
                <hr></hr>
                <div className="form-group mt-5">
                  <label htmlFor="formGroupExampleInput">Your Post Title</label>
                  <input
                    type="text"
                    className="form-control"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />

                  <label htmlFor="formGroupExampleInput">
                    Short discription about your post
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={sDiscription}
                    onChange={(e) => setSDiscription(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="formGroupExampleInput">Photo url</label>
                  <input
                    type="text"
                    className="form-control"
                    value={purl}
                    onChange={() => setPUrl(purl)}
                  />
                </div>
                <hr className="new"></hr>
                <p className="h4 mt-3">General</p>
                <hr className="new"></hr>
                <MDBRow className="mt-2">
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">Brand</label>
                    <input
                      type="text"
                      className="form-control"
                      value={brand}
                      onChange={(e) => setBrand(e.target.value)}
                    />
                  </MDBCol>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">Laptop type</label>
                    <input
                      type="text"
                      className="form-control"
                      value={ltype}
                      onChange={(e) => setLtype(e.target.value)}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mt-2">
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">Screen size </label>
                    <input
                      type="text"
                      className="form-control"
                      value={screensize}
                      onChange={(e) => setScreensize(e.target.value)}
                    />
                  </MDBCol>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">
                      Screen speaker
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={screenspeaker}
                      onChange={(e) => setScreenspeaker(e.target.value)}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">Touchscreen</label>
                    <input
                      type="text"
                      className="form-control"
                      value={touchscreen}
                      onChange={(e) => setTouchscreen(e.target.value)}
                    />
                  </MDBCol>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">
                      Other display specs
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={otherdis}
                      onChange={(e) => setOtherdis(e.target.value)}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">
                      Processor (CPU){" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={cputype}
                      onChange={(e) => setCputype(e.target.value)}
                    />
                  </MDBCol>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">
                      Graphics (GPU){" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={gputype}
                      onChange={(e) => setGputype(e.target.value)}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">
                      System memory (RAM) size
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={ram}
                      onChange={(e) => setRam(e.target.value)}
                    />
                  </MDBCol>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">Data storage </label>
                    <input
                      type="text"
                      className="form-control"
                      value={storage}
                      onChange={(e) => setStorage(e.target.value)}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">
                      DVD optical drive{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={dvd}
                      onChange={(e) => setDvd(e.target.value)}
                    />
                  </MDBCol>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">
                      Keyboard, input{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={keyboard}
                      onChange={(e) => setKeyboard(e.target.value)}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">
                      Speakers and audio{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={speaker}
                      onChange={(e) => setSpeaker(e.target.value)}
                    />
                  </MDBCol>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">Camera </label>
                    <input
                      type="text"
                      className="form-control"
                      value={camera}
                      onChange={(e) => setCamera(e.target.value)}
                    />
                  </MDBCol>
                </MDBRow>
                <hr className="new"></hr>
                <p className="h4 mt-3">work below</p>
                <hr className="new"></hr>
                <MDBRow>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">
                      Wi-Fi wireless
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={wifi}
                      onChange={(e) => setWifi(e.target.value)}
                    />
                  </MDBCol>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">
                      Ethernet network port(Yes/No)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={etherNet}
                      onChange={(e) => setEtherNet(e.target.value)}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">
                      Mobile broadband (4G LTE)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={mobBroad}
                      onChange={(e) => setMobBroad(e.target.value)}
                    />
                  </MDBCol>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">
                      Bluetooth (Yes/No)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={bluetooth}
                      onChange={(e) => setBluetooth(e.target.value)}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">USB ports</label>
                    <input
                      type="text"
                      className="form-control"
                      value={usbPort}
                      onChange={(e) => setUsbPort(e.target.value)}
                    />
                  </MDBCol>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">Video ports</label>
                    <input
                      type="text"
                      className="form-control"
                      value={videoPort}
                      onChange={(e) => setVideoPort(e.target.value)}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">
                      Media card reader{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={mediaCard}
                      onChange={(e) => setMediaCard(e.target.value)}
                    />
                  </MDBCol>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">Battery </label>
                    <input
                      type="text"
                      className="form-control"
                      value={battery}
                      onChange={(e) => setBattery(e.target.value)}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">Weight</label>
                    <input
                      type="text"
                      className="form-control"
                      value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                    />
                  </MDBCol>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">
                      Dimensions(h*w*d)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={dimension}
                      onChange={(e) => setDimension(e.target.value)}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">
                      Windows version{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={windowVersion}
                      onChange={(e) => setWindowVersion(e.target.value)}
                    />
                  </MDBCol>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">Warranty</label>
                    <input
                      type="text"
                      className="form-control"
                      value={warranty}
                      onChange={(e) => setWarranty(e.target.value)}
                    />
                  </MDBCol>
                </MDBRow>
                <hr className="new"></hr>
                <p className="h4 mt-3">Affiliate link addition</p>
                <hr className="new"></hr>
                <MDBRow>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">Amazon.in</label>
                    <input
                      type="text"
                      className="form-control"
                      value={amazone}
                      onChange={(e) => setAmazone(e.target.value)}
                    />
                  </MDBCol>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">Flipkart</label>
                    <input
                      type="text"
                      className="form-control"
                      value={flipkart}
                      onChange={(e) => setFlipkart(e.target.value)}
                    />
                  </MDBCol>
                </MDBRow>
                <hr className="new"></hr>
                <p className="h4 mt-3">Video link addition</p>
                <hr className="new"></hr>
                <MDBRow>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">
                      Unboxing Video
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={unboxing}
                      onChange={(e) => setUnboxing(e.target.value)}
                    />
                  </MDBCol>
                  <MDBCol>
                    <label htmlFor="formGroupExampleInput">Review Video</label>
                    <input
                      type="text"
                      className="form-control"
                      value={review}
                      onChange={(e) => setReview(e.target.value)}
                    />
                  </MDBCol>
                </MDBRow>
                <div className="mt-3">
                  <p>Details discription</p>
                  <SunEditor
                    height="50vh"
                    autoFocus={true}
                    setContents={detailDes}
                    onChange={setDetailDes}
                  />
                  <div> {detailDes}</div>
                </div>
                <div className="text-center pt-3">
                  <MDBBtn
                    onClick={() => submit()}
                    outline
                    color="info btn-outline-primary"
                  >
                    Post &nbsp;
                    <MDBIcon icon="location-arrow" className="ml-1 " />
                  </MDBBtn>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol lg="3" md="12">
            <MDBCard className="mt-5">
              <MDBCardHeader>Post categories</MDBCardHeader>
              <MDBCardBody>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="defaultUnchecked1"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="defaultUnchecked1"
                  >
                    Laptop
                  </label>
                </div>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="defaultUnchecked2"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="defaultUnchecked2"
                  >
                    Mobile
                  </label>
                </div>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="defaultUnchecked3"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="defaultUnchecked3"
                  >
                    Gadgets
                  </label>
                </div>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="defaultUnchecked4"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="defaultUnchecked4"
                  >
                    Books
                  </label>
                </div>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="defaultUnchecked5"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="defaultUnchecked5"
                  >
                    Linux fix
                  </label>
                </div>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="defaultUnchecked6"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="defaultUnchecked6"
                  >
                    Server fixed
                  </label>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}
